import React, { useState } from "react";
import useViewport from "../../../hooks/useViewport";
import { Wrapper } from "../../Forms/components/component";
import  {useDispatch} from 'react-redux'

import SigninForm from "../../Forms/Signin";
import { signinInit } from "../../../store/actions/auth";
import { useNavigate } from "react-router-dom";



const SigninModal = () => {
  const { isMobile } = useViewport();
  const [defaultActiveKey, setActivekey] = useState( "1");
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const onSignin = (e) => {
    dispatch(signinInit({form : {email : e?.email, password : e?.password}, navigate}))
  }


  return (
    <Wrapper
      isMobile={isMobile}
      closable={true}
      centered
      maskStyle={{
        background: "rgba(138, 149, 186, 0.4)",
        backdropFilter: "blur(10px)",
      }}
      style={{ height: "auto", padding: 0 }}
      visible={true}
      width={700}
  
      footer={""}

      bodyStyle={{
        borderRadius: 50,
      }}
    >
      <SigninForm
        defaultActiveKey={defaultActiveKey}
        setActivekey={setActivekey}
        onFinish={onSignin}
        type="modal"
      />
    </Wrapper>
  );
};
export default SigninModal;
