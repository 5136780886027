import React, { useState } from "react";
import { Card, Form, Row, Col } from "antd";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";

import useViewport from "../../../hooks/useViewport";
import { Wrapper } from "../components/component";
import { InputField, Button } from "../../_shared";
import { Link, useNavigate } from "react-router-dom";
// import { ROUTES } from "../../../routes/constant";


const SigninForm = ({defaultActiveKey, setActivekey, onFinish }) => {
  const [showpass, setShowpass] = useState(false);

  const { isMobile } = useViewport();
  const navigate = useNavigate()


  return (
    <Wrapper isMobile={isMobile}>
      <Card className="card">
        <p className="title">Signin</p>
      
        <Form
          layout="vertical"
          onFinish={onFinish}
        >
          <Row gutter={16}>
            <Col xs={24} sm={24} md={24} xl={24} xxl={24} span={24}>
              <Form.Item
                name="email"
                rules={[
                  {
                    type: "email",
                    required: true,
                    message: "Enter valid email address",
                  },
                ]}
              >
                <InputField
                  className="font-600"
                  type="email"
                  placeholder="Email"
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} xl={24} xxl={24} span={24}>
              <Form.Item
                name="password"
                rules={[
                  { required: true, message: "Password is required field" },
                ]}
              >
                <InputField
                  type={showpass ? "text" : "password"}
                  suffix={
                    showpass ? (
                      <EyeInvisibleOutlined
                        onClick={() => {
                          setShowpass(!showpass);
                        }}
                      />
                    ) : (
                      <EyeOutlined
                        onClick={() => {
                          setShowpass(!showpass);
                        }}
                      />
                    )
                  }
                  className="font-600"
                  placeholder="Password"
                />
              </Form.Item>
            </Col>
          </Row>

          <div
            className="center-column"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <Button
              size={"large"}
              title="Back"
              onClick={() => {
                navigate("/");
              }}
              className="continue back_button"
            />
            <Button
              htmlType="submit"
              size={"large"}
              style={{ marginLeft: isMobile ? 0 : 20 }}
              title="Continue"
              className="continue"
            />
          </div>
        </Form>
  
        <p className="already-register">
          Don’t have an account?
          <Link
            to={{
              // pathname: ROUTES.SIGNUP,
              state: {
                active: "Test",
              },
            }}
            className="color-blue cursor-pointer"
            onClick = {() => navigate("/signup")}
          >
            {" "}
            Sign up
          </Link>
        </p>
      </Card>
    </Wrapper>
  );
};
export default SigninForm;
