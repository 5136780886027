// import React, { useState } from "react";
// import Vimeo from "react-vimeo";
// import {PlayCircleFilled} from '@ant-design/icons'
// import LoadingVideo from "../../../../components/_shared/Loader/LoadingVideo";

// const VimeoPlayer = ({ id }) => {
//   const [error, setError] = useState(false);

//   return (
//     <>
//       {/* {error ? (
//         <>
//           <LoadingVideo isLoading={true} />
//         </>
//       ) : ( */}
//         <div style={{height:600}}>
//         <Vimeo videoId={Number(id)}
//         className="vimeo-player-iframe"
//         width={"100%"}
//         height={600}
//         onError={(e) => {
//           setError(true);
//         }}
//         onLoaded={() => {
//           setError(false);
//         }}
//         playButton={<PlayCircleFilled style={{height:150,width:150}} />}
//         // playButton={true}
//         showTitle={false}
//         showByline={false}
//         autoplay={true}
//         loop={true} />
//         </div>
//       {/* )} */}
//     </>
//   );
// };

// export default VimeoPlayer;

import React, { useState } from "react";
import Vimeo from "@u-wave/react-vimeo";
import LoadingVideo from "../../../../components/_shared/Loader/LoadingVideo";
import { useEffect } from "react";
import { Tooltip } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import usevideoStatus from "../../../../hooks/useVideostatus";

const VimeoPlayer = ({ id, timeline, getVideoLink, refreshTimeline }) => {
  const [error, setError] = useState(false);
  const [isError, setIsError] = useState(false);
  // const { data } = usevideoStatus(Number(id));
  // console.log(data, "datadatadata");
  useEffect(() => {
    if (error) {
      console.log(isError);
      setTimeout(() => {
        setIsError(!isError);
      }, 3000);
    }
  }, [error, isError]);

  return (
    <>
      {error && isError ? (
        <>
          <LoadingVideo
            refreshTimeline={refreshTimeline}
            isLoading={true}
            type="autocheck"
          />
        </>
      ) : (
        <>
          {" "}
          {timeline?.makeCopy && (
            <Tooltip title="Download" color={"#0eaaa5"} key={"download"}>
              <DownloadOutlined
                style={{ position: "absolute", left: 0 }}
                onClick={() => getVideoLink(id)}
                className=" h4 cursor-pointer"
              />
            </Tooltip>
          )}
          <Vimeo
            video={Number(id)}
            width={"100%"}
            height={600}
            onError={(e) => {
              setError(true);
            }}
            onLoaded={() => {
              setError(false);
            }}
            showTitle={false}
            showByline={false}
            autoplay={false}
          />
        </>
      )}
    </>
  );
};

export default VimeoPlayer;
