 const  chat=async ()=> {
    var Tawk_API = Tawk_API || {};

    var Tawk_LoadStart = new Date();

    await (function () {
        var s1 = document.createElement('script'),
            s0 = document.getElementsByTagName('script')[0];
        s1.async = true;
        s1.src='https://embed.tawk.to/61e5ad02f7cf527e84d2986a/1fpkinl8v';
        s1.charset = 'UTF-8';
        s1.setAttribute('crossorigin', '*');
        s0.parentNode.insertBefore(s1, s0);
    })();
}

export default chat

